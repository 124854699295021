import all_seeing_eye from "../assets/img/all-seeing-eye.png";
import bandana from "../assets/img/bandana.png";
import bones from "../assets/img/bones.png";
import broom from "../assets/img/broom.png";
import calendar from "../assets/img/calendar.png";
import candle from "../assets/img/candle.png";
import candy_bag from "../assets/img/candy-bag.png";
import cauldron from "../assets/img/cauldron.png";
import crystal_ball from "../assets/img/crystal-ball.png";
import crystal_ball2 from "../assets/img/crystal-ball2.png";
import devil from "../assets/img/devil.png";
import drum_stick from "../assets/img/drum-stick.png";
import eye_ball from "../assets/img/eye-ball.png";
import fire from "../assets/img/fire.png";
import ghost from "../assets/img/ghost.png";
import horn from "../assets/img/horn.png";
import knife from "../assets/img/knife.png";
import love_potion from "../assets/img/love-potion.png";
import magic_ball from "../assets/img/magic-ball.png";
import magic_dust from "../assets/img/magic-dust.png";
import magic_hat from "../assets/img/magic-hat.png";
import magic_lamp from "../assets/img/magic-lamp.png";
import magic_potion from "../assets/img/magic-potion.png";
import magic_trolley from "../assets/img/magic-trolley.png";
import magic_wand from "../assets/img/magic-wand.png";
import magic_wand2 from "../assets/img/magic-wand2.png";
import magic_wand3 from "../assets/img/magic-wand3.png";
import magician from "../assets/img/magician.png";
import monster from "../assets/img/monster.png";
import nail from "../assets/img/nail.png";
import ouija_board from "../assets/img/ouija-board.png";
import poison from "../assets/img/poison.png";
import raven from "../assets/img/raven.png";
import runes from "../assets/img/runes.png";
import spider_web from "../assets/img/spider-web.png";
import spider from "../assets/img/spider.png";
import target from "../assets/img/target.png";
import trident from "../assets/img/trident.png";
import vikings_helmet from "../assets/img/vikings.png";
import voodoo_doll from "../assets/img/voodoo-doll.png";
import water from "../assets/img/water.png";
import witch_broom from "../assets/img/witch-broom.png";
import witch_hat from "../assets/img/witch-hat.png";
import witch_shoes from "../assets/img/witch-shoes.png";

const symbols = [
  { name: "all seeing eye", path: all_seeing_eye },
  { name: "bandana", path: bandana },
  { name: "bones", path: bones },
  { name: "broom", path: broom },
  { name: "calendar", path: calendar },
  { name: "candle", path: candle },
  { name: "candy bag", path: candy_bag },
  { name: "cauldron", path: cauldron },
  { name: "crystal ball", path: crystal_ball },
  { name: "crystal ball", path: crystal_ball2 },
  { name: "devil", path: devil },
  { name: "drum stick", path: drum_stick },
  { name: "eye ball", path: eye_ball },
  { name: "fire", path: fire },
  { name: "ghost", path: ghost },
  { name: "horn", path: horn },
  { name: "knife", path: knife },
  { name: "love potion", path: love_potion },
  { name: "magic ball", path: magic_ball },
  { name: "magic dust", path: magic_dust },
  { name: "magic hat", path: magic_hat },
  { name: "magic lamp", path: magic_lamp },
  { name: "magic potion", path: magic_potion },
  { name: "magic trolley", path: magic_trolley },
  { name: "magic wand", path: magic_wand },
  { name: "magic wand", path: magic_wand2 },
  { name: "magic wand", path: magic_wand3 },
  { name: "magician", path: magician },
  { name: "monster", path: monster },
  { name: "nail", path: nail },
  { name: "ouija board", path: ouija_board },
  { name: "poison", path: poison },
  { name: "raven", path: raven },
  { name: "runes", path: runes },
  { name: "spider web", path: spider_web },
  { name: "spider", path: spider },
  { name: "target", path: target },
  { name: "trident", path: trident },
  { name: "vikings helmet", path: vikings_helmet },
  { name: "voodoo doll", path: voodoo_doll },
  { name: "water", path: water },
  { name: "witch broom", path: witch_broom },
  { name: "witch hat", path: witch_hat },
  { name: "witch shoes", path: witch_shoes },
];

const randomSymbols = () => {
  const all = [...symbols];
  let selected = [];
  for (let i = 0; i < all.length; i++) {
    const choice = all[Math.floor(Math.random() * all.length)];
    selected.push(choice);
  }
  for (let i = selected.length; i < 100; i++) {
    const choice = selected[Math.floor(Math.random() * selected.length)];
    selected.push(choice);
  }
  const choosen = selected[0];
  for (let i = 9; i < selected.length; i += 9) {
    selected.splice(i, 1, choosen);
  }
  let symbolObj = {};
  for (let i = 0; i < selected.length; i++) {
    const count = i < 10 ? "0" + i : i;
    symbolObj[count] = selected[i];
  }
  const objToReturn = { target: choosen, others: symbolObj };
  return objToReturn;
};

export default randomSymbols;
