import { Link } from "react-router-dom";
import useClick from "../hooks/useClick";
import LayoutDefault from "./LayoutDefault";
const Home = () => {
  useClick();
  return (
    <>
      <LayoutDefault
        Main={
          <div className="msg-wrapper home-navs">
            <Link to="/start">Start</Link>
            <Link to="/secret">Secret</Link>
            <a href="/more-games">More games</a>
            <Link to="/options">Options</Link>
          </div>
        }
      />
    </>
  );
};

export default Home;
