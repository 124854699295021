import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Home,
  Start,
  Secret,
  Options,
  Symbol,
} from "./components/all-components";

const App = () => {
  console.log("React");
  return (
    <div className="App">
      <div className="container">
        <Router>
          <Routes>
            <Route path="/start">
              <Route index element={<Start />} />
              <Route path=":id" element={<Start />} />
            </Route>
            <Route path="/secret" element={<Secret />} />
            <Route path="/options" element={<Options />} />
            <Route path="/symbols" element={<Symbol />} />
            <Route path="/*" element={<Home />}></Route>
          </Routes>
        </Router>
      </div>
    </div>
  );
};

export default App;
