import { useEffect } from "react";
import click from "../assets/click.mp3";
const useClick = () => {
  useEffect(() => {
    const allBtns = [
      ...document.querySelectorAll("a"),
      ...document.querySelectorAll("button"),
    ];
    const handlePlayClickAudio = () => {
      const audio = new Audio(click);
      audio.play();
    };
    allBtns.forEach((btn) =>
      btn.addEventListener("click", handlePlayClickAudio)
    );
    return () =>
      allBtns.forEach((btn) =>
        btn.removeEventListener("click", handlePlayClickAudio)
      );
  });
};

export default useClick;
