import { useState } from "react";
import { SymbolsPage, Comfirmation, Results } from "./LayoutSessions";

const Sessions = ({ symbols, target }) => {
  const [session, setSession] = useState("symbols");
  return (
    <>
      <SymbolsPage
        symbols={symbols}
        setSession={setSession}
        display={session === "symbols" ? "show" : null}
      />
      {session === "confirmation" && (
        <Comfirmation session={session} setSession={setSession} />
      )}
      {session === "results" && <Results result={target} />}
    </>
  );
};

export default Sessions;
